<template>
    <div>
        <div class="modal fade" id="FileViewer" tabindex="-1" aria-labelledby="FileViewerLabel" aria-hidden="true" style="z-index: 9999;">
            <div class="modal-dialog modal-lg modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 id="FileViewerLabel" class="font-weight-bold">{{FileName}}</h4>
                        <a type="button" data-bs-dismiss="modal" aria-label="Close">
                            <i class="fa fa-times"></i>
                        </a>
                    </div>

                    <div class="modal-body">
                        <iframe
                            id="fileFrame"
                            src="#"
                            frameBorder="0"
                            scrolling="auto"
                            height="600px"
                            width="100%"
                        ></iframe>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { apiBaseUrlDownload } from '../infrastructure/constant/connection'

export default {
    name: 'FileViewer',
    components: {
    },
    data: function () {
        return {
            FileName : '',
        }
    },
    methods: {
        viewClick(filename){
            this.FileName = filename;

            var file = this.FileName.split(".");
            var fileType = file.at(-1);

            var fileUrl = apiBaseUrlDownload + filename;

            if(fileType == 'xls' || fileType == 'xlsx' || fileType == 'csv'){
                window.open(fileUrl, "_blank");
            }else{
                document.getElementById('fileFrame').src = fileUrl;
                window.$('#FileViewer').modal('show');
            }
        },
    },
}
</script>
