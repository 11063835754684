<template>
    <div>
        <div class="modal fade" id="employeeModal" tabindex="-1" aria-labelledby="employeeModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-xl modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 id="employeeModalLabel" class="font-weight-bold">{{ModalTitle}}{{EmployeeCode}}</h4>
                        <a type="button" data-bs-dismiss="modal" aria-label="Close">
                            <i class="fa fa-times"></i>
                        </a>
                    </div>

                    <div class="modal-body">
                        <CRow>
                            <CCol sm="2">
                                <label class="form-label font-weight-bold required">Kode</label>
                                <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                            </CCol>
                            <CCol sm="4">
                                <CInput id="EmployeeCode" v-model="EmployeeCode" class="font-weight-bold" />
                                <label id="errorEmployeeCode" class="form-error" style="display: none; color: red;"></label>
                            </CCol>               
                        </CRow>
                        <CRow>
                             <CCol sm="2">
                                <label class="form-label font-weight-bold required">Nama</label>
                                <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                            </CCol>
                            <CCol sm="4">
                                <CInput id="EmployeeName" v-model="EmployeeName" class="font-weight-bold" />
                                <label id="errorEmployeeName" class="form-error" style="display: none; color: red;"></label>
                            </CCol>    
                            <CCol sm="2">
                                <label class="form-label font-weight-bold">Status</label>
                                <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                            </CCol>
                            <CCol sm="4">
                                <CSelect id="Status" class="font-weight-bold" :options="StatusData" :value.sync="Status" />
                                <label id="errorStatus" class="form-error" style="display: none; color: red;"></label>
                            </CCol>                                     
                        </CRow>
                        <hr>

                        <CTabs>
                            <CTab title="Profil" id="tab-1" active>
                                <div class="p-3">
                                    <CRow>
                                        <CCol sm="2">
                                            <label class="form-label font-weight-bold">Provinsi</label>
                                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                        </CCol>
                                        <CCol sm="4">
                                            <v-select id="Province" class="pb-3" :options="ProvinceData" v-model="Province" @input="onChange()" />
                                            <label id="errorProvince" class="form-error" style="display: none; color: red;"></label>
                                        </CCol>
                                        <CCol sm="2">
                                        </CCol>
                                        <CCol sm="2">
                                            <div>
                                                <input type="checkbox" v-model="TaxFree"/> <label>Bebas Pajak</label>
                                            </div>
                                            <label id="errorTaxFree" class="form-error" style="display: none; color: red;"></label>
                                        </CCol>
                                    </CRow>
                                    <CRow>
                                        <CCol sm="2">
                                            <label class="form-label font-weight-bold">Kota</label>
                                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                        </CCol>
                                        <CCol sm="4">
                                            <v-select id="City" class="pb-3" :options="CityData" v-model="City"/>
                                            <label id="errorCity" class="form-error" style="display: none; color: red;"></label>
                                        </CCol>
                                        <CCol sm="2">
                                            <label class="form-label font-weight-bold required">Jabatan</label>
                                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                        </CCol>
                                        <CCol sm="4">
                                            <CInput id="Position" v-model="Position" class="font-weight-bold" />
                                            <label id="errorPosition" class="form-error" style="display: none; color: red;"></label>
                                        </CCol>       
                                    </CRow>
                                    <CRow>
                                        <CCol sm="2">
                                            <label class="form-label font-weight-bold required">Alamat</label>
                                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                        </CCol> 
                                        <CCol sm="4" class="pb-3">
                                            <textarea class="form-control" rows="2" v-model="Address"></textarea>
                                            <label id="errorAddress" class="form-error pt-3" style="display: none; color: red;"></label>
                                        </CCol>
                                    </CRow>
                                    <CRow>
                                        <CCol sm="2">
                                            <label class="form-label font-weight-bold">Telp 1</label>
                                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                        </CCol>
                                        <CCol sm="4">
                                            <CInput id="Phone1" v-model="Phone1" class="font-weight-bold" />
                                            <label id="errorPhone1" class="form-error" style="display: none; color: red;"></label>
                                        </CCol>
                                        <CCol sm="2">
                                            <label class="form-label font-weight-bold">Telp 2</label>
                                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                        </CCol>
                                        <CCol sm="4">
                                            <CInput id="Phone2" v-model="Phone2" class="font-weight-bold" />
                                            <label id="errorPhone2" class="form-error" style="display: none; color: red;"></label>
                                        </CCol>
                                    </CRow>
                                    <CRow>
                                        <CCol sm="2">
                                            <label class="form-label font-weight-bold">No. HP 1</label>
                                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                        </CCol>
                                        <CCol sm="4">
                                            <CInput id="HandPhone1" v-model="HandPhone1" class="font-weight-bold" />
                                            <label id="errorHandPhone1" class="form-error" style="display: none; color: red;"></label>
                                        </CCol>
                                       <CCol sm="2">
                                            <label class="form-label font-weight-bold">No. HP 2</label>
                                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                        </CCol>
                                        <CCol sm="4">
                                            <CInput id="HandPhone2" v-model="HandPhone2" class="font-weight-bold" />
                                            <label id="errorHandPhone2" class="form-error" style="display: none; color: red;"></label>
                                        </CCol>
                                    </CRow>
                                    <CRow>
                                        <CCol sm="2">
                                            <label class="form-label font-weight-bold">NPWP</label>
                                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                        </CCol>
                                        <CCol sm="4">
                                            <CInput id="NPWP" v-model="NPWP" class="font-weight-bold" />
                                            <label id="errorNPWP" class="form-error" style="display: none; color: red;"></label>
                                        </CCol>
                                        <CCol sm="2">
                                            <label class="form-label font-weight-bold">Grade</label>
                                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                        </CCol>
                                        <CCol sm="4">
                                            <CInput id="Grade" v-model="Grade" class="font-weight-bold" />
                                            <label id="errorGrade" class="form-error" style="display: none; color: red;"></label>
                                        </CCol>
                                    </CRow>
                                    <CRow>
                                        <CCol sm="2">
                                            <label class="form-label font-weight-bold required">NIK</label>
                                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                        </CCol>
                                        <CCol sm="4">
                                            <CInput id="NIK" v-model="NIK" class="font-weight-bold" />
                                            <label id="errorNIK" class="form-error" style="display: none; color: red;"></label>
                                        </CCol>
                                        <CCol sm="2">
                                            <label class="form-label font-weight-bold">Area</label>
                                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                        </CCol>
                                        <CCol sm="4">
                                            <CSelect id="Area" class="font-weight-bold" :options="AreaData" :value.sync="Area" />
                                            <label id="errorArea" class="form-error" style="display: none; color: red;"></label>
                                        </CCol>
                                    </CRow>
                                    <CRow>
                                        <CCol sm="2">
                                            <label class="form-label font-weight-bold required">Catatan</label>
                                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                        </CCol> 
                                        <CCol sm="4" class="pb-3">
                                            <textarea class="form-control" rows="2" v-model="Note"></textarea>
                                            <label id="errorNote" class="form-error pt-3" style="display: none; color: red;"></label>
                                        </CCol>
                                    </CRow>
                                    <CRow>
                                        <CCol sm="2">
                                            <label class="form-label font-weight-bold">Supervisor</label>
                                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                        </CCol>
                                        <CCol sm="4">
                                            <v-select id="Supervisor" class="pb-3" :options="SupervisorData" v-model="Supervisor"/>
                                            <label id="errorSupervisor" class="form-error" style="display: none; color: red;"></label>
                                        </CCol>
                                        <CCol sm="2">
                                            <label class="form-label font-weight-bold">Kacab</label>
                                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                        </CCol>
                                        <CCol sm="4">
                                            <v-select id="Kacab" class="pb-3" :options="KacabData" v-model="Kacab"/>
                                            <label id="errorKacab" class="form-error" style="display: none; color: red;"></label>
                                        </CCol>
                                    </CRow>
                                    <CRow>
                                        <CCol sm="2">
                                            <label class="form-label font-weight-bold">Attachment</label>
                                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                        </CCol>
                                        <CCol sm="4">
                                            <div class="row">
                                                <div class="col-8 p-0 pl-3">
                                                    <div class="form-control">
                                                        <div v-if="this.AttachmentStatus == 'Uploaded'">
                                                            <a href="#" @click="attachmentClick()" class="label-file" id="uploadedAttachment"><label>{{Attachment}}</label></a>
                                                        </div>
                                                        <div v-if="this.AttachmentStatus == 'New'">
                                                            <label class="label-file">{{Attachment}}</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-2 p-0 px-2">
                                                    <label for="Attachment" class="form-control" style="text-align: center;"><i class="fa fa-upload"></i></label>
                                                    <input type="file" id="Attachment" @input="onChangeAttachment()" style="display:none"/>
                                                </div>
                                                <div class="col-2 p-0 pr-3">
                                                    <CButton type="button" @click="deleteAttachmentClick()" id="deleteAttachmentButton" color="danger" style="width:100%"> <i class="fa fa-trash"></i> </CButton>
                                                </div>
                                            </div>
                                            <label id="errorAttachment" class="form-error" style="display: none; color: red;"></label>
                                        </CCol>
                                        <CCol sm="2">
                                            <label class="form-label font-weight-bold">Jenis</label>
                                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                        </CCol>
                                        <CCol sm="4">
                                            <CSelect id="ProfileType" class="font-weight-bold" :options="ProfileTypeData" :value.sync="ProfileType" />
                                            <label id="errorProfileType" class="form-error" style="display: none; color: red;"></label>
                                        </CCol>
                                    </CRow>
                                </div>
                            </CTab>
                            <CTab title="Target Penjualan" id="tab-2">
                                <div class="p-3">
                                    <datasource ref="dataSourceSale" :data="this.GridDataSale" :page-size="10" :schema-model-fields="this.SchemaModelSale"/>

                                    <kendo-grid ref="gridSale"
                                        :data-source-ref="'dataSourceSale'"
                                        :pageable="true"
                                        :filterable="true"
                                        :sortable="true"
                                        :editable="true"
                                        :resizable="true"
                                        >
                                        
                                        <kendo-grid-column  :field="'group_type_id'"
                                                            :title="'Kode Tipe Group'"
                                                            :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"></kendo-grid-column>

                                        <kendo-grid-column  :field="'group_type_name'"
                                                            :title="'Nama Tipe Group'"
                                                            :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"></kendo-grid-column>

                                        <kendo-grid-column  :field="'target'"
                                                            :title="'Target'"
                                                            :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"
                                                            :attributes=" { 'class': 'k-text-right' }"></kendo-grid-column>

                                    </kendo-grid>
                                </div>
                            </CTab>
                            <CTab title="Target Komisi Retail" id="tab-3">
                                <div class="p-3">
                                    <CRow>
                                        <CCol sm="4">
                                            <label class="form-label font-weight-bold">Penjualan</label>
                                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                        </CCol>
                                        <CCol sm="4">
                                            <CInput type="number" id="Sale" v-model="Sale" class="font-weight-bold" />
                                            <label id="errorSale" class="form-error" style="display: none; color: red;"></label>
                                        </CCol>
                                        <CCol sm="2">
                                            <div>
                                                <input type="checkbox" v-model="SaleStatus"/> <label>Aktif</label>
                                            </div>
                                            <label id="errorSaleStatus" class="form-error" style="display: none; color: red;"></label>
                                        </CCol>
                                    </CRow>
                                    <CRow>
                                        <CCol sm="4">
                                            <label class="form-label font-weight-bold">Jumlah Edukasi Kontraktor</label>
                                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                        </CCol>
                                        <CCol sm="4">
                                            <CInput type="number" id="ContractorEducation" v-model="ContractorEducation" class="font-weight-bold" />
                                            <label id="errorContractorEducation" class="form-error" style="display: none; color: red;"></label>
                                        </CCol>
                                        <CCol sm="2">
                                            <div>
                                                <input type="checkbox" v-model="ContractorEducationStatus"/> <label>Aktif</label>
                                            </div>
                                            <label id="errorContractorEducationStatus" class="form-error" style="display: none; color: red;"></label>
                                        </CCol>
                                    </CRow>
                                    <CRow>
                                        <CCol sm="4">
                                            <label class="form-label font-weight-bold">Kunjungan Toko</label>
                                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                        </CCol>
                                        <CCol sm="4">
                                            <CInput type="number" id="StoreVisit" v-model="StoreVisit" class="font-weight-bold" />
                                            <label id="errorStoreVisit" class="form-error" style="display: none; color: red;"></label>
                                        </CCol>
                                        <CCol sm="2">
                                            <div>
                                                <input type="checkbox" v-model="StoreVisitStatus"/> <label>Aktif</label>
                                            </div>
                                            <label id="errorStoreVisitStatus" class="form-error" style="display: none; color: red;"></label>
                                        </CCol>
                                    </CRow>
                                    <CRow>
                                        <CCol sm="4">
                                            <label class="form-label font-weight-bold">Terlambat</label>
                                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                        </CCol>
                                        <CCol sm="4">
                                            <CInput type="number" id="Late" v-model="Late" class="font-weight-bold" />
                                            <label id="errorLate" class="form-error" style="display: none; color: red;"></label>
                                        </CCol>
                                        <CCol sm="2">
                                            <div>
                                                <input type="checkbox" v-model="LateStatus"/> <label>Aktif</label>
                                            </div>
                                            <label id="errorLateStatus" class="form-error" style="display: none; color: red;"></label>
                                        </CCol>
                                    </CRow>
                                    <CRow>
                                        <CCol sm="4">
                                            <label class="form-label font-weight-bold">Absensi / Kehadiaran</label>
                                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                        </CCol>
                                        <CCol sm="4">
                                            <CInput type="number" id="Absent" v-model="Absent" class="font-weight-bold" />
                                            <label id="errorAbsent" class="form-error" style="display: none; color: red;"></label>
                                        </CCol>
                                        <CCol sm="2">
                                            <div>
                                                <input type="checkbox" v-model="AbsentStatus"/> <label>Aktif</label>
                                            </div>
                                            <label id="errorAbsentStatus" class="form-error" style="display: none; color: red;"></label>
                                        </CCol>
                                    </CRow>
                                    <CRow>
                                        <CCol sm="4">
                                            <label class="form-label font-weight-bold">Data Valid Kontraktor</label>
                                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                        </CCol>
                                        <CCol sm="4">
                                            <CInput type="number" id="ContractorValidedData" v-model="ContractorValidedData" class="font-weight-bold" />
                                            <label id="errorContractorValidedData" class="form-error" style="display: none; color: red;"></label>
                                        </CCol>
                                        <CCol sm="2">
                                            <div>
                                                <input type="checkbox" v-model="ContractorValidedDataStatus"/> <label>Aktif</label>
                                            </div>
                                            <label id="errorContractorValidedDataStatus" class="form-error" style="display: none; color: red;"></label>
                                        </CCol>
                                    </CRow>
                                </div>
                            </CTab>
                        </CTabs>
                        <CButton type="button" @click="saveClick()" class="save-button" id="saveButton" color="success" style="float: right;"> Save </CButton>
                        <label style="float: right;">&nbsp;&nbsp;</label>
                        <CButton type="button" color="secondary" style="float: right;" data-bs-dismiss="modal" aria-label="Close"> Close </CButton>
                    </div>
                </div>
            </div>
        </div>
        <file-viewer ref="fileViewer"/>
    </div>
</template>

<script>
import { globalfunc } from '../../../../shared/GlobalFunction.js';
import employeeServices from '../Script/EmployeeServices.js';
import datePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import moment from 'moment';
import { Grid, GridColumn} from '@progress/kendo-grid-vue-wrapper';
import { DataSource } from '@progress/kendo-datasource-vue-wrapper';
import FileViewer from '../../../../shared/FileViewer.vue';
import { response } from '../../../../infrastructure/constant/response';

export default {
    name: 'EmployeeForm',
    components: {
        'date-picker' : datePicker,
        'file-viewer': FileViewer,
        'kendo-grid' : Grid,
        'kendo-grid-column': GridColumn,
        'datasource' : DataSource,
    },
    props: ['reload'],
    data: function () {
        return {
            ModalTitle : '',
            SaveType : '',
            Error: 0,
            ProfileError : 0,
            RetailCommisionTargetError: 0,

            Id : '',
            EmployeeCode : '',
            EmployeeName : '',
            Status : '',
            StatusData: [],

            //Profile
            Province : '',
            ProvinceData : [],
            TaxFree : '',
            City : '',
            CityData : [],
            Position : '',
            Address : '',
            Phone1 : '',
            Phone2 : '',
            HandPhone1 : '',
            HandPhone2 : '',
            NPWP : '',
            Grade : '',
            NIK : '',
            Area : '',
            AreaData : [],
            Note : '',
            Supervisor : '',
            SupervisorData : [],
            Kacab : '',
            KacabData : [],
            ProfileType : '',
            ProfileTypeData : [ {value: 'All', label: 'All'},
                                {value: 'Project', label: 'Project'},
                                {value: 'Retail', label: 'Retail'}],
            
            // RetailCommisionTargetError
            Sale : 0,
            SaleStatus : '',
            ContractorEducation : 0,
            ContractorEducationStatus : '',
            StoreVisit : 0,
            StoreVisitStatus : '',
            Late : 0,
            LateStatus : '',
            Absent : 0,
            AbsentStatus : '',
            ContractorValidedData : 0,
            ContractorValidedDataStatus : '',

            Attachment: '',
            AttachmentStatus: '',

            //grid
            GridDataSale: [],
            SchemaModelSale: {
                group_type_name: { type: "string", editable: false},
                target: { type: "number", editable: true, validation: { min: 0 }}
            },
        }
    },
    methods: {
        onChangeAttachment(){
            var attachment = document.getElementById('Attachment').files[0];
            
            this.AttachmentStatus = 'New';
            this.Attachment = attachment.name;
        },
        attachmentClick(){
            this.$refs.fileViewer.viewClick(this.Attachment);
        },
        async addClick(){   
            $(".form-error").css("display", "none");  
            this.ModalTitle = 'Add Employee: ';

            this.EmployeeCode = 'Auto';
            this.EmployeeName = '';
            this.StatusData = [{value: 'N', label: 'New'}];
            this.Status = this.StatusData[0].value;

            this.TaxFree = false;
            
            this.ProvinceData = this.$globalfunc.getProvinceData();
            this.Province = this.ProvinceData.find(c => c.value == "DKI Jakarta");
            this.CityData = this.$globalfunc.getCityData(this.Province.value);
            this.City = this.CityData.find(c => c.value == "Jakarta Utara");

            this.Position = '';
            this.Address = '';
            this.Phone1 = '';
            this.Phone2 = '';
            this.HandPhone1 = '';
            this.HandPhone2 = '';
            this.NPWP = '';
            this.Grade = '';
            this.NIK = '';
            this.AreaData = await employeeServices.getAreaQuery();
            this.Area = this.AreaData[0].value;
            this.Note = '';
            this.SupervisorData = await employeeServices.getKacabQuery(null);
            this.Supervisor = null;
            this.KacabData = await employeeServices.getKacabQuery(null);
            this.Kacab = null;
            this.ProfileType = 'All';

            this.Sale = 0;
            this.SaleStatus = true;
            this.ContractorEducation = 0;
            this.ContractorEducationStatus = true;
            this.StoreVisit = 0;
            this.StoreVisitStatus = true;
            this.Late = 0;
            this.LateStatus = true;
            this.Absent = 0;
            this.AbsentStatus = true;
            this.ContractorValidedData = 0;
            this.ContractorValidedDataStatus = true;

            //grid
            this.GridDataSale = [];

            this.SaveType = 'Add';
            $('#tab-1').trigger('click');
            document.getElementById('EmployeeCode').readOnly = false;
            window.$('#employeeModal').modal('show');
        },
        async editClick(id, View) {
            var data = await employeeServices.getDetailContact(id);

            $(".form-error").css("display", "none");
            $(".save-button").show();

            this.ModalTitle = 'Edit Employee: ';
            this.Id = data.contact_id;
            this.EmployeeCode = data.contact_code;
            this.EmployeeName = data.contact_name;

            this.StatusData = [ {value: 'A', label: 'Active'},
                                {value: 'I', label: 'Inactive'}];
            this.Status = data.status=="N"?"A":data.status;
            
            var profileData = data.metadata.profile;
            this.TaxFree = profileData.taxfree;
           
            this.ProvinceData = this.$globalfunc.getProvinceData();
            this.Province = this.ProvinceData.find(c => c.value == profileData.province);
            this.CityData = this.$globalfunc.getCityData(this.Province.value);
            this.City = this.CityData.find(c => c.value == profileData.city);

            this.Position = profileData.position;
            this.Address = profileData.address;
            this.Phone1 = profileData.phone1;
            this.Phone2 = profileData.phone2;
            this.HandPhone1 = profileData.handphone1;
            this.HandPhone2 = profileData.handphone2;
            this.NPWP = profileData.npwp;
            this.Grade = profileData.grade;
            this.NIK = profileData.nik;
            this.Area = profileData.area;
            this.AreaData = await employeeServices.getAreaQuery();
            this.Note = profileData.note;
            this.SupervisorData = await employeeServices.getKacabQuery(this.EmployeeCode);
            this.Supervisor = this.SupervisorData.find(c => c.value == profileData.supervisor);
            this.KacabData = await employeeServices.getKacabQuery(this.EmployeeCode);
            this.Kacab = this.KacabData.find(c => c.value == profileData.kacab);
            this.ProfileType = profileData.profiletype;

            //File
            this.Attachment = data.attachment1;
            this.AttachmentStatus = 'Uploaded';
            
            var retailCTData = data.metadata.retail_comission_target;
            this.Sale = retailCTData.sales;
            this.SaleStatus = retailCTData.salestatus;
            this.ContractorEducation = retailCTData.contractor_education;
            this.ContractorEducationStatus = retailCTData.contractor_education_status;
            this.StoreVisit = retailCTData.store_visit;
            this.StoreVisitStatus = retailCTData.store_visit_status;
            this.Late = retailCTData.late;
            this.LateStatus = retailCTData.late_status;
            this.Absent = retailCTData.absent;
            this.AbsentStatus = retailCTData.absent_status;
            this.ContractorValidedData = retailCTData.contractor_valided_data;
            this.ContractorValidedDataStatus = retailCTData.contractor_valided_data_status;

            //grid
            this.GridDataSale = await employeeServices.getSalesTargetData(this.Id);

            this.SaveType = 'Edit';
            $('#tab-1').trigger('click');
            document.getElementById('EmployeeCode').readOnly = true;

            if(View){
                $(".save-button").hide();
                this.ModalTitle = 'View Employee: ';
            }

            window.$('#employeeModal').modal('show');
        },
        async inputValidation() {
            this.Error = 0;
            this.ProfileError = 0;
            this.RetailCommisionTargetError = 0;

            $(".form-error").css("display", "none");

            if(this.EmployeeCode == '' || this.EmployeeCode == null){
                this.errorShow('errorEmployeeCode');
            }
            if(this.EmployeeName == '' || this.EmployeeName == null){
                this.errorShow('errorEmployeeName');
            }
            if(this.Position == '' || this.Position == null){
                this.errorShow('errorPosition');
                this.ProfileError++;
            }
            if(this.Address == '' || this.Address == null){
                this.errorShow('errorAddress');
                this.ProfileError++;
            }
            if(this.NIK == '' || this.NIK == null){
                this.errorShow('errorNIK');
                this.ProfileError++;
            }
            if(this.Area == '' || this.Area == null){
                this.errorShow('errorArea');
                this.ProfileError++;
            }
            if(this.Note == '' || this.Note == null){
                this.errorShow('errorNote');
                this.ProfileError++;
            }
            if(this.Sale.toString() == '' || this.Sale == null){
                this.errorShow('errorSale');
            }
            if(this.ContractorEducation.toString() == '' || this.ContractorEducation == null){
                this.errorShow('errorContractorEducation');
                this.RetailCommisionTargetError++;
            }
            if(this.StoreVisit.toString() == '' || this.StoreVisit == null){
                this.errorShow('errorStoreVisit');
                this.RetailCommisionTargetError++;
            }
            if(this.Late.toString() == '' || this.Late == null){
                this.errorShow('errorLate');
                this.RetailCommisionTargetError++;
            }
            if(this.Absent.toString() == '' || this.Absent == null){
                this.errorShow('errorAbsent');
                this.RetailCommisionTargetError++;
            }
            if(this.ContractorValidedData.toString() == '' || this.ContractorValidedData == null){
                this.errorShow('errorContractorValidedData');
                this.RetailCommisionTargetError++;
            }

            if(this.ProfileError != 0){
                this.$swal("Error", "Terdapat error pada tab profil", "error");
            }
            else if(this.RetailCommisionTargetError != 0) {
                this.$swal("Error", "Terdapat error pada tab target komisi retail", "error");
            }
        },
        saveClick() {
            this.inputValidation();

            var attachment = document.getElementById('Attachment').files[0];
            if(attachment == undefined)(
                attachment = null
            )

            if(this.Error == 0){
                const profile = {
                    province : this.Province.value,
                    taxfree : this.TaxFree,
                    city :  this.City.value,
                    position : this.Position,
                    address : this.Address,
                    phone1 : this.Phone1,
                    phone2 : this.Phone2,
                    handphone1 : this.HandPhone1,
                    handphone2 : this.HandPhone2,
                    npwp : this.NPWP,
                    grade : this.Grade,
                    nik : this.NIK,
                    area : this.Area,
                    note : this.Note,
                    supervisor : this.Supervisor==null?null:this.Supervisor.value,
                    kacab : this.Kacab==null?null:this.Kacab.value,
                    profiletype : this.ProfileType
                }

                //Sale Target Grid Data
                var saleTargetGridData = this.$refs.gridSale.kendoWidget().dataSource._data;
                var saleTarget = [];
                for (let i = 0; i < saleTargetGridData.length; i++) {
                    var str = 
                    { 
                        group_type_id: saleTargetGridData[i].group_type_id,
                        group_type_name: saleTargetGridData[i].group_type_name,
                        target: saleTargetGridData[i].target
                    }
                    saleTarget.push(str);
                }

                const sale_target = saleTarget

                const retail_comission_target = {
                    sales : this.Sale,
                    salestatus : this.SaleStatus,
                    contractor_education : this.ContractorEducation,
                    contractor_education_status : this.ContractorEducationStatus,
                    store_visit : this.StoreVisit,
                    store_visit_status : this.StoreVisitStatus,
                    late : this.Late,
                    late_status : this.LateStatus,
                    absent : this.Absent,
                    absent_status : this.AbsentStatus,
                    contractor_valided_data : this.ContractorValidedData,
                    contractor_valided_data_status : this.ContractorValidedDataStatus
                }
                
                const metadata = {
                    profile : profile,
                    sale_target : sale_target,
                    retail_comission_target : retail_comission_target
                }

                const employee = {
                    contact_code : this.EmployeeCode,
                    contact_type : "Employee",
                    contact_name : this.EmployeeName,
                    status : this.Status,
                    metadata : metadata,
                    attachment1: attachment,
                    attachment2: null,
                }
                
                if (this.SaveType == 'Add') {
                    this.$loading(true);

                    const variables = {
                        data : employee
                    }

                    employeeServices.addQuery(variables).then(res => {
                        this.$loading(false);
                        this.$swal("Info", response.successSave, "success");
                        window.$('#employeeModal').modal('hide');
                        this.$props.reload();
                    }).catch(error => {
                        this.$loading(false);
                        var errorMessage = globalfunc.ErrorExtractor(error)
                        this.$swal("Error", errorMessage, "error");
                    })
                }
                else if (this.SaveType == 'Edit') {
                    this.$loading(true);

                    const variables = {
                        id : this.Id,
                        data : employee
                    }
                    
                    employeeServices.editQuery(variables).then(res => {
                        this.$loading(false);
                        this.$swal("Info", response.successUpdate, "success");
                        window.$('#employeeModal').modal('hide');
                        this.$props.reload();
                    }).catch(error => {
                        this.$loading(false);
                        var errorMessage = globalfunc.ErrorExtractor(error)
                        this.$swal("Error", errorMessage, "error");
                    })
                }
            }
        },
        errorShow(errorCode){
            document.getElementById(errorCode).style.display = 'block';
            document.getElementById(errorCode).innerHTML = response.fieldRequired;
            if(this.Error <= this.$globalvariable.max_error_focus){ document.getElementById(errorCode.replace("error", "")).focus(); }
            this.Error++;
        },
        onChange(){
            this.City = '';
            if (this.Province == null){
                this.CityData = []
            }
            else{
                this.CityData = this.$globalfunc.getCityData(this.Province.value);
            }
        },
        deleteAttachmentClick(){            
            $('#Attachment').val('');
            this.AttachmentStatus = '';
            this.Attachment = '';
        }
    }
}
</script>

<style scoped>
.col-sm-label, .col-sm-content, .col-sm-half {
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
}
@media (min-width: 768px) {
    .col-sm-label, .col-sm-content, .col-sm-half {
        float: left;
    }
    .col-sm-label {
        width: 12%;
    }
    .col-sm-content {
        width: 21%;
    }
    .col-sm-half{
        width: 33%;
    }
    .input-number{
        text-align: right;
    }
}
>>> {
    --vs-controls-size: 0.7;
    --vs-actions-padding: 4px 2px 0 3px;
    --vs-border-color: #d8dbe0;
    --vs-selected-color: #768192;
    --vs-dropdown-option-color: #768192;
    --vs-selected-max-height: 10px;
}
.label-file {
  width : 100%;
  overflow:hidden;
  display:inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
