import gql from 'graphql-tag';
import {globalfunc} from '../../../../shared/GlobalFunction.js';

class ExpeditionService {
    readExpeditionQuery(){
        var query = `query($type:String, $status:String){
                        GetContact(ContactType:$type, ActiveFlag:$status){
                            contact_id
                            contact_code
                            contact_type
                            contact_name
                            status
                            metadata
                        }
                    }`;
        return query;
    }

    async getDetailContact(id){
        const variables = {
            id : id
        }
        var query = gql`query($id:Int){
            GetContact(ContactId:$id){
                created_at
                last_update
                contact_id
                contact_code
                contact_type
                contact_name
                status
                metadata
                attachment1
                attachment2
            }
        }`;
        var result = await globalfunc.defaultApolloQueryDefault(query, variables);
        
        return result.data.GetContact[0];
    }
    
    async addQuery(variables){
        var query = gql`mutation($data:NewContact!){
            CreateContact(contact:$data){
              response
            }
        }`;

        return await globalfunc.defaultApolloMutationDefault(query, variables)
    }

    async editQuery(variables){
        var query = gql`mutation($id:Int!,$data:NewContact!){
            UpdateContact(contactID:$id,contact:$data){
              response
            }
        }`;
          
        return await globalfunc.defaultApolloMutationDefault(query, variables)
    }

    async deleteQuery(variables){
        var query = gql`mutation($id:Int!){
            DeleteContact(contactID:$id){
                response
            }
        }`;

        return await globalfunc.defaultApolloMutationDefault(query, variables)
    }
}

export default new ExpeditionService();