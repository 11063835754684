import gql from 'graphql-tag';
import {globalfunc} from '../../../../shared/GlobalFunction.js';

class EmployeeService {
    readEmployeeQuery(){
        var query = `query($type:String, $status:String){
                        GetContact(ContactType:$type, ActiveFlag:$status){
                            contact_id
                            contact_code
                            contact_type
                            contact_name
                            status
                            metadata
                        }
                    }`;

        return query;
    }

    async getDetailContact(id){
        const variables = {
            id : id
        }
        var query = gql`query($id:Int){
            GetContact(ContactId:$id){
                created_at
                last_update
                contact_id
                contact_code
                contact_type
                contact_name
                status
                metadata
                attachment1
                attachment2
            }
        }`;
        var result = await globalfunc.defaultApolloQueryDefault(query, variables);
        
        return result.data.GetContact[0];
    }

    async addQuery(variables){
        var query = gql`mutation($data:NewContact!){
            CreateContact(contact:$data){
              response
            }
        }`;

        return await globalfunc.defaultApolloMutationDefault(query, variables)
    }

    async editQuery(variables){
        var query = gql`mutation($id:Int!,$data:NewContact!){
            UpdateContact(contactID:$id,contact:$data){
              response
            }
        }`;
          
        return await globalfunc.defaultApolloMutationDefault(query, variables)
    }

    async deleteQuery(variables){
        var query = gql`mutation($id:Int!){
            DeleteContact(contactID:$id){
                response
            }
        }`;

        return await globalfunc.defaultApolloMutationDefault(query, variables)
    }

    async getNewGroupType(){
        var query = gql`query{
                        GetGroupType(ActiveFlag:"A"){
                            group_type_name
                        }
                    }`;
        var result = await globalfunc.defaultApolloQueryDefault(query);    
        var newResult = result.data.GetGroupType;
        newResult.forEach(element => {
            element.target = 0;
        });           
        return newResult;
    }

    async getSalesTargetData(id){
        const variables = {
            id : parseInt(id)
        }
        var query = gql`query ($id:Int) {
            GetSalesTarget (ContactId:$id) {
                group_type_id
                group_type_name
                target
            }
        }`;
        var result = await globalfunc.defaultApolloQueryDefault(query,variables);
        return result.data.GetSalesTarget;
    }

    async getAreaQuery(){
        var query = gql`query{
            GetAreaCode{
                areacode
                areaname
            }
        }`;
        var result = await globalfunc.defaultApolloQueryDefault(query);
        var areaData = [];
        for (let i = 0; i < result.data.GetAreaCode.length; i++) {
            var label = result.data.GetAreaCode[i].areacode + ' (' + result.data.GetAreaCode[i].areaname + ')'
            var str = {value:result.data.GetAreaCode[i].areacode, label:label}
            areaData.push(str);
        }
        return areaData
    }

    async getKacabQuery(id){
        var query = gql`query{
            GetContact(ContactType:"Employee"){
                contact_id
                contact_name
            }
        }`;
        var result = await globalfunc.defaultApolloQueryDefault(query);
        var data = result.data.GetContact;
        var kacabData = [];
        if(data.length != null){
            for (let i = 0; i < data.length; i++) {
                var str = { value: data[i].contact_id, 
                            label: data[i].contact_name}
                kacabData.push(str);
            }
        }

        if(id == null){
            return kacabData;
        }else{
            return this.deleteSelectedIdGrid(id, kacabData);
        }
        
    }

    deleteSelectedIdGrid(id, data){
        var hashMap = [];
        data.forEach(el=>{
            hashMap[el.value]= el
        })
        delete hashMap[id.toString()]
        var newData = [];
        Object.keys(hashMap).forEach(el=>{
            newData.push(hashMap[el])
        })

        return newData;
    }

    statusButtonGenerator(statusList){
        var start = '<div class="btn-group w-100">',
        main = '<button id="btnAll" style="width:12%" class="btn btn-secondary btn-All">All&nbsp;<b>'+'999'+'</b></button>&nbsp;',
        end = '</div><hr>';

        if(statusList != null){
            for (let i = 0; i < statusList.length; i++){
                var str = '<button id="btn' + statusList[i].toString().replace(/\s/g, '') + '" style="width:12%" class="btn btn-secondary btn-'+ statusList[i].toString().replace(/\s/g, '') +'" >' + statusList[i].toString() + '&nbsp;<b>'+ '999'+ '</b></button>&nbsp;';
                main = main + str;
            }
        }

        return (start + main + end);
    }
}

export default new EmployeeService();